<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            type="year"
            default="today"
            label="목표년도"
            name="year"
            v-model="searchParam.targetYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="MAIN_SAFETY_HEALTHY_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="safetyHealthyTypeCd"
            label="구분"
            v-model="searchParam.safetyHealthyTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            :isFirstValue="false"
            label="해당부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="기준정보 목록"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :columnSetting="false"
        :isExcelDown="false"
        :isFullScreen="false"
        :filtering="false"
        :usePaging="false"
        selection="multiple"
        rowKey="mainSafetyHealthyId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="복사" icon="save_alt" @btnClicked="copyMaster" />
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addMaster" />
            <c-btn 
              v-if="editable" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="saveData"
              mappingType="PUT"
              label="저장" 
              icon="save" 
              @beforeAction="saveMaster"
              @btnCallback="saveMasterCallback" />
              <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-healthy-mst',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '300px',
        top: '',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        deptCd: '',
        safetyHealthyTypeCd: null,
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:90px',
            sortable: false,
            type: 'plant',
          },
          {
            required: true,
            name: 'targetYear',
            field: 'targetYear',
            label: '목표년도',
            align: 'center',
            setHeader: true,
            sortable: false,
            style: 'width:70px',
            type: 'date',
            dateType: 'year',
          },
          {
            setHeader: true,
            name: 'safetyHealthyTypeCd',
            field: 'safetyHealthyTypeCd',
            label: '구분',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            codeGroupCd: 'MAIN_SAFETY_HEALTHY_TYPE_CD',
          },
          {
            required: true,
            name: 'targetName',
            field: 'targetName',
            label: '목표',
            align: 'left',
            style: 'width:290px',
            sortable: false,
            type: 'text',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '해당부서',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            type: 'deptMulti',
            deptCd: 'deptCd',
            isFirstValue: false,
          },
          // {
          //   name: 'useFlag',
          //   field: 'useFlag',
          //   label: '사용여부',
          //   align: 'center',
          //   style: 'width:50px',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            sortable: false,
            style: 'width:70px',
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [],
      isSave: false,
      editable: true,
      saveUrl: '',
      listUrl: '',
      saveData: [],
      shLabel: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.mainsafetyHealthy.base.list.url;
      this.saveUrl = transactionConfig.sai.mainsafetyHealthy.base.save.url;
      this.deleteUrl = transactionConfig.sai.mainsafetyHealthy.base.delete.url;
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addMaster() {
      this.grid.data.splice(0, 0, {
        saiSafetyHealthyId: uid(),  // 안전보건목표 일련번호
        plantCd: null,  // 사업장
        targetYear: this.$comm.getThisYear(),
        targetName: '',  // 안전보건목표
        sortOrder: 0,  // 안전보건목표
        deptCd: '',  // 안전보건목표
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C',
        safetyHealthyTypeCd: null,
      })
    },
    copyMaster() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.popupOptions.target = () => import(`${"./mainSafetyHealthyMstCopy.vue"}`);
        this.popupOptions.title = 'ESF목표 복사대상 년도'; 
        this.popupOptions.width = '400px';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup(result) {
      if (result.year !== undefined) {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        let selectData = this.$refs['grid'].selected;
        this.$_.forEach(selectData, _data => {
          _data.editFlag = 'C';
          _data.targetYear = result.year;
        })
        this.saveData = selectData;
        if(this.saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',  
            message: '선택한 목표를 해당년도로 복사하시겠습니까?', 
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      } else {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
      }
    },
    saveMaster() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        this.saveData = this.grid.data.filter(item => {
          return item.editFlag === 'C' || item.editFlag === 'U' 
        });
        if(this.saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?', 
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    saveMasterCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?', 
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
